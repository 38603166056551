.wrapper-container{
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: row;
    background-color: var(--primary-color);
}

.wrapper-container-white{
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: row;
    background-color: var(--white-color);
}

.wrapper-component{
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 4rem 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 450px) {
    .wrapper-component{
        padding: 4rem 1rem 2rem;
    }
  }