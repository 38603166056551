/*applies to all content on intro page*/
.intro{
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    /*space for nav bar*/
    padding: 6rem 2rem 0rem;
}
.intro-name-text{
    font-size: 5rem;
    padding-left: 5rem;
}

@media screen and (min-width: 2000px) {
    .intro{
        padding-top: 8rem;
    }
  }

@media screen and (max-width: 1200px) {
    .intro{
        flex-direction: column;
    }
}

@media screen and (max-width: 450px) {
    .intro{
        padding: 6rem 1rem 2rem;
        
    }
    .intro-name-text{
        font-size: 2rem;
    }
}

/*applies to intro text*/
.intro-text-motion{
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

    margin: 0 2rem;
}

@media screen and (max-width: 1200px) {
    .intro-text-motion{
        width: 100%;
        margin-right: 0rem;
    }
}