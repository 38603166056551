.page-indicator{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
}

.page-indicator a{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #cbcbcb;
    margin: 0.5rem;

    transition: background-color 0.2s ease-in-out;
}

.page-indicator a:hover{
    background-color: var(--secondary-color);
}

@media screen and (max-width: 900px) {
    .page-indicator{
        display: none;
    }
}