.navbar{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;                 /* 1rem top-bottom 2rem side*/

    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); 
    backdrop-filter: blur(4px);                     /*provides a blurred background for tag*/
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    position: fixed;
    z-index: 2;     /* makes appear above content*/
}

.navbar-logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;  
}


.navbar-logo img{
    width: 90px;
    height: 50px; 
    mix-blend-mode: multiply;
}

.navbar-links {
    flex: 1;                /*makes list takes up more space*/
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
}

/*makes it responsive navbar when size of screen is too small*/
@media screen and (max-width: 800px) {
    .navbar-links {
        display: none;
    }
}

.navbar-links li{
    margin: 0 1rem;
    cursor: pointer;
    
    flex-direction: column;
}

.navbar-links li div{
    width: 5px;
    height: 5px;
    background: transparent;
    border-radius: 50%;
    
    margin-bottom: 5px;
}

.navbar-links li a{
    color: var(--gray-color);
    text-decoration: none;
    flex-direction: column;
    text-transform: uppercase;
    font-weight: 500;   
    transition: all 0.3s ease-in-out;
}

.navbar-links li a:hover{
    color: var(--secondary-color);
}

.navbar-links li:hover div{
    background: var(--secondary-color);
}

.navbar-links-format{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    text-align: left;
    color: var(--gray-color);
    line-height: 1.5;
}

@media screen and (min-width: 2000px) {
    .navbar-links-format{
        font-size: 1.75rem;
    }
}

.navbar-menu{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
}
.navbar-menu svg{
    width: 70%;
    height: 70%;
    color: var(--white-color);
}

.navbar-menu div{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    
    padding: 1rem;
    
    width: 60%; 
    height: 100vh;
    
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    background-color: var(--white-color);

    box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);
}

.navbar-menu div>svg{
    width: 35px;
    height: 35px;
    color: var(--secondary-color);
    margin: 0.5rem 1rem;
}


.navbar-menu-ul{
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.navbar-menu-ul li{
    margin: 1rem;
}

.navbar-menu-ul li a{
    color: var(--gray-color);
    text-decoration: none;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    
    transition: all 0.3s ease-in-out;
}

.navbar-menu-ul li a:hover{
    color: var(--secondary-color);
}

@media screen and (min-width: 800px) {
     .navbar-menu{
        display: none;
    }
} 


